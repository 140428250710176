// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: "development",
    production: false,
    msalConfig: {
        auth: {
            clientId: '36c8c549-30ef-412e-afca-133e6eaad678',
            authority: 'https://login.microsoftonline.com/0ad43537-6a69-43de-83b7-3f6b37c0201d'
        }
    },
    apiConfig: {
        scopes: ['api://36c8c549-30ef-412e-afca-133e6eaad678/access_via_approle_assignments'],
        uri: 'https://graph.microsoft.com/v1.0/me'
    },
    
    onboardProdUrl: "https://new.onboard.yourexpats.nl",
    selectProdUrl:  "https://new.select.yourexpats.nl",
    findProdUrl:    "https://find.yourexpats.nl",
    testProdUrl:    "https://new.test.yourexpats.nl",

    onboardStagUrl:"https://stag.onboard.yourexpats.nl",
    selectStagUrl: "https://stag.select.yourexpats.nl",
    findStagUrl:   "https://staging.find.yourexpats.nl",
    testStagUrl:   "https://stag.test.yourexpats.nl",

    onboardDevUrl:  "https://dev.onboard.yourexpats.nl",
    selectDevUrl:   "https://dev.select.yourexpats.nl",
    findDevUrl:     "https://dev.find.yourexpats.nl",
    testDevUrl:     "https://dev.test.yourexpats.nl",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.